import React, { useState, useEffect } from 'react'
import working from '../assets/images/learn1-working.jpg'
import womenWorking from '../assets/images/women-working.jpg'
import teamWorking from '../assets/images/team-working.jpg'
import groupWorking from '../assets/images/group-working.jpg'
import LoadingSpinner from '../components/LoadingSpinner'

const imageUrls = [
  '../assets/images/learn1-working.jpg',
  '../assets/images/women-working.jpg',
  '../assets/images/team-working.jpg',
  '../assets/images/group-working.jpg' 
]

const LearnPage = () => {
  const [loading, setLoading] = useState(true)
  const [loadedCount, setLoadedCount] = useState(0)
  
  useEffect(() => {
    const handleImageLoad = () => {
      setLoadedCount((prev) => prev + 1);
    };

    imageUrls.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
      img.onerror = handleImageLoad; // handle error similarly to avoid blocking the spinner removal
    });
  }, []);

  useEffect(() => {
    if (loadedCount === imageUrls.length) {
      setLoading(false);
    }
  }, [loadedCount]);

  if (loading) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className='flex flex-col justify-center items-center w-full mb-16'>
      <div className='flex flex-col gap-2 sm:text-6xl text-5xl w-screen font-semibold mb-16 justify-center items-center mt-8 tracking-wider'>
        {/* <h1 className=''>We help your ads get</h1>
        <h1>clicks</h1> */}
        <div className='flex flex-col w-fit'>
          <h1 className=''>Enhancing Engagement,</h1>
          <h1 className='sm:mr-12 '>Driving Results</h1>
        </div>

      </div>
      <div className='flex flex-col w-full gap-10'>
        <div className='flex sm:flex-row flex-col-reverse sm:gap-0 gap-4 w-screen'>
          <div className='w-1/2'>
            <div className='relative sm:h-[500px] h-[320px] sm:w-2/3'>
              <div className='bg-[#B6A8E3] rounded-r-lg h-full'>
              <div className='absolute sm:right-[-200px] right-[-100px] sm:top-16 top-10'>
                <img className='sm:h-[380px] h-[240px] rounded-lg shadow-xl' src={working} />
              </div>
              </div>
            </div>
          </div>

          <div className='sm:ml-48 flex flex-col justify-center items-center sm:mx-auto mx-4'>
            <div className='sm:w-[400px] flex flex-col gap-6 tracking-wide sm:mr-12'>
              <h3 className='text-3xl font-medium text-left'>About Us</h3>
              <p className='text-pretty text-left font-medium'>Kwixi is a leading AI chatbot agency that specializes in creating, deploying, and maintaining custom chatbots for businesses. Advanced AI technology provides human-like responses to enhance customer engagement and operational efficiency.</p>

              <p className='text-pretty text-left font-medium'>Whether it’s handling customer service, generating leads, or scheduling meetings, Kwixi ensures seamless integration and delivers measurable results. This allows your business to focus on its core activities while we manage the technology.</p>
            </div>
          </div>
        </div>


        <div className='flex sm:flex-row flex-col w-screen sm:mt-8 my-4'>
          <div className='sm:mr-48 sm:mx-auto mx-4 flex flex-col justify-center items-end sm:w-1/2'>
            <div className='sm:w-[400px] flex flex-col gap-6 tracking-wide'>
              <h3 className='text-3xl font-medium text-left'>Key Services and Features</h3>
              <p className='text-pretty text-left font-medium'>Our tailored customer service chatbots provide fast and accurate responses customized to your specific business needs, optimizing support and enhancing user satisfaction.</p>
              <p className='text-pretty text-left font-medium'>Kwixi chatbots are designed to engage visitors and capture lead information, integrating smoothly with your CRM to streamline lead generation and scheduling. By automating customer interactions, our solutions help boost your sales growth, expand your sales pipeline, and improve overall team efficiency.</p>
            </div>

          </div>

          <div className='sm:w-1/2 mt-4 sm:mt-0 flex justify-end'>
            <div className='w-2/3 sm:h-[600px] h-[340px] bg-[#aff0ca] rounded-l-xl relative'>
              <div className='absolute sm:top-24 top-12 left-[-80px] sm:left-[-160px]'>
                <img className='sm:h-[400px] h-[240px] rounded-xl shadow-xl' src={womenWorking} />
              </div>
            </div>
          </div>
        </div>

        <div className='flex sm:flex-row flex-col-reverse w-screen sm:mt-8 gap-2'>
          <div className='sm:w-1/2'>
            <div className='relative w-2/3 bg-[#917ad0] sm:h-[600px] h-[340px] rounded-r-xl'>
              <div className='absolute sm:right-[-180px] right-[-60px] sm:top-24 top-12'>
                <img src={teamWorking} className='sm:h-[400px] h-[240px] rounded-xl' />
              </div>
            </div>
          </div>

          <div className='sm:ml-48 flex flex-col justify-center items-center'>
            <div className='sm:w-[400px] flex flex-col gap-6 tracking-wide sm:mr-12 sm:mx-auto mx-4'>
              <h3 className='text-3xl font-medium text-left'>Comprehensive Management and Learning</h3>
              <p className='text-pretty text-left font-medium'>Kwixi offers end-to-end management of your AI chatbot, from initial design and deployment to ongoing maintenance, ensuring a smooth and hassle-free experience. Our chatbots learn from your content to deliver knowledgeable and contextually relevant responses to customer inquiries</p>
              <p className='text-pretty text-left font-medium'>We provide detailed analytics through monthly reports and a real-time dashboard, helping you monitor performance and make data-driven decisions. Continuous updates and new feature integrations keep you informed and ensure your chatbot remains at the cutting edge of technology.</p>
            </div>

          </div>
        </div>

        <div className='flex sm:flex-row flex-col gap-2 w-screen mt-8'>
          <div className='sm:mr-40 flex flex-col justify-center items-end sm:w-1/2'>
            <div className='sm:w-[400px] flex flex-col gap-6 tracking-wide sm:mx-auto mx-4'>
              <h3 className='text-3xl font-medium text-left'>Integration, Customization, and Scalability</h3>
              <p className='text-pretty text-left font-medium'>Kwixi chatbots are compatible with various website platforms and CRM systems, providing a seamless integration experience. Our customization options include multilingual support and design personalization to match your brand’s identity. Efficient scheduling is made possible with integrations like Calendly.</p>
              <p className='text-pretty text-left font-medium'>Our chatbots are built with scalability in mind, ready to grow with your business and adapt to increasing workloads and evolving needs. Advanced AI features, such as intelligent automation and stringent data privacy measures, ensure precise responses and secure data handling, keeping your operations smooth and compliant with industry standards.</p>
            </div>

          </div>

          <div className='sm:w-1/2 flex justify-end'>
            <div className='w-2/3 sm:h-[600px] h-[320px] bg-[#BDEECE] rounded-l-xl relative'>
              <div className='absolute sm:top-24 top-8 left-[-90px] sm:left-[-200px]'>
                <img className='sm:h-[400px] h-[260px] rounded-xl shadow-xl' src={groupWorking} />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default LearnPage