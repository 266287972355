import React, { useState, useEffect } from 'react'
import useMobileDetect from '../hooks/mobileHook';
import KwixiLogo from '../components/KwixiLogo'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import handleOpenChatbot from '../utils/ChatbotOpened';
import { Hamburger, Close } from '../components/Icons'

import { FaUserCircle } from "react-icons/fa";

const Navbar = () => {
  const isMobile = useMobileDetect()

  const navItems = [
    { id: "/", label: "Home" },
    { id: "plans", label: "Plans" },
    { id: "learn", label: "Learn" },
    { id: "contact", label: "Contact" },
    { id: "signin", label: "Members" },
  ]
  return (
    !isMobile ? (
      <div className='h-[92px] fixed top-0 left-0 w-full flex justify-center items-center bg-[#FFFFFF] z-50'>
        <div className='h-full flex justify-center items-center'>
          
          {/* <div className='flex items-center mr-2'> */}
            <div className='w-14 h-auto mb-2 z-10 mr-2'>
              <KwixiLogo />
            </div>

          {/* </div> */}


            <div className='border-y border-[#CCCCCC] h-[42px] flex items-center pl-1 pr-0.5'>
              <div className='h-fit mr-8 flex justify-center items-center  transition duration-200 ease-in-out  hover:-translate-y-1 active:translate-y-0'>
                <NavLink to='/' className='text-[30px] leading-none tracking-wider font-bold '>Kwixi</NavLink>
              </div>

              <ul className='flex gap-8 text-[11px] tracking-[.2rem] uppercase font-black'>
                {navItems.map((item) => (
                  <li className='transition duration-200 ease-in-out  hover:-translate-y-1 active:translate-y-0'>
                    <NavLink
                      className={({ isActive }) => 
                        isActive ? 'text-[#9780DD]' : ''
                      }   
                      to={item.id}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className='flex gap-4 ml-4 justify-center items-center'>
              <div
                // className='text-3xl'
                className='text-[#180A3F] text-3xl tracking-wider rounded-full transition duration-200 ease-in-out hover:text-[#2A1260] hover:shadow-md hover:-translate-y-1 active:translate-y-0'
                role='button'>
                  <Link to='/signin'>
                    <FaUserCircle />
                  </Link>
                </div>
              <button
                onClick={handleOpenChatbot}
                className='bg-[#180A3F] text-white text-[16px] w-[120px] h-[40px] tracking-wider py-2 rounded-full transition duration-200 ease-in-out hover:bg-[#2A1260] hover:shadow-sm hover:-translate-y-1 active:translate-y-0'
                // className='bg-content text-[#FAF7FF] rounded-full w-[120px] hover:bg-[#2A1260] hover:shadow-sm hover:shadow-[#2A1260] transition-colors tracking-wider h-[40px] text-[16px]'
                >
                Let's chat
              </button>
            </div>
        </div>
      </div>
    ) : (
      <MobileNavbar />
    )
  )
}

export default Navbar


const MobileNavbar = () => {
  const [navMenuOpen, setNavMenuOpen] = useState(false)
  const [shouldOpenChatbot, setShouldOpenChatbot] = useState(false);
  const navigate = useNavigate()

  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setNavMenuOpen((prev) => !prev);
  };

  const handleDemoClick = () => {
    navigate('/contact');
    setNavMenuOpen(false)
  };

  const handleChatClick = () => {
    setNavMenuOpen(false);
    setShouldOpenChatbot(true);
  };

  useEffect(() => {
    if (shouldOpenChatbot) {
      handleOpenChatbot();
      setShouldOpenChatbot(false);
    }
  }, [shouldOpenChatbot]);

  useEffect(() => {
    const htmlElement = document.documentElement;
    const bodyElement = document.body;

    if (navMenuOpen) {
      htmlElement.classList.add('no-scroll');
      bodyElement.classList.add('no-scroll');
    } else {
      htmlElement.classList.remove('no-scroll');
      bodyElement.classList.remove('no-scroll');
    }

    // Cleanup function to ensure body overflow is reset when component unmounts
    return () => {
      htmlElement.classList.remove('no-scroll');
      bodyElement.classList.remove('no-scroll');
    };
  }, [navMenuOpen]);


  return (
    <div className={`fixed top-0 left-0 w-screen z-50 flex h-[80px] justify-between items-center pr-4 bg-[#F6F6F6] ${navMenuOpen ? 'nav-menu-open' : ''}`}>

      <NavLink onClick={() => setNavMenuOpen(false)} className='flex gap-2 ml-2' to='/'>
        <div className='size-8'><KwixiLogo /></div>
        <div className='text-[30px] font-bold'>Kwixi</div>
      </NavLink>

      <div role='button' onClick={toggleDropdown} className='size-7'>
        {navMenuOpen ? <Close /> : <Hamburger /> }
      </div>
      {navMenuOpen && (
        <div className='flex flex-col justify-between fixed inset-0 top-20 left-0 z-20 w-full  uppercase  bg-[#F6F6F6] overflow-hidden'>
          <ul className='text-left text-[24px] font-semibold text-content mt-4'>
            <li className='p-6 border-b border-slate-300'><NavLink onClick={toggleDropdown} to='/plans' className='block w-full h-full'>plans</NavLink></li>
            <li className='p-6 border-b border-slate-300'><NavLink onClick={toggleDropdown} to='/learn' className='block w-full h-full'>learn</NavLink></li>
            <li className='p-6 border-b border-slate-300'><NavLink onClick={toggleDropdown} to='/contact' className='block w-full h-full'>contact</NavLink></li>
            <li className='p-6 border-b border-slate-300'><NavLink onClick={toggleDropdown} to='/signin' className='block w-full h-full'>members</NavLink></li>
          </ul>

          <div className='flex flex-col mt-auto mx-4 mb-8 gap-6'>
            <button onClick={handleChatClick} className='rounded-lg w-1/2 bg-content text-base py-4 mx-auto'>Let's Chat</button>
            <button onClick={handleDemoClick} className='rounded-lg w-1/2 bg-kwixi-green py-4 mx-auto'>Book a Demo</button>
          </div>
        </div>
      )}
    </div>
  )
}