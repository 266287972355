import React from 'react'
import { Link } from 'react-router-dom';

import { MdOutlineStars } from "react-icons/md";

import gradient from '../assets/images/gradient.png'

import womanOnPhone from '../assets/images/woman-with-laptop-documents.jpg'
import manWorking from '../assets/images/man-working.jpg'
import speedGauge from '../assets/images/speed-gauge.png'
import { AnimatedImage, AnimatedDiv } from '../components/AnimatedElement';


const ProductPage = () => {
  return (
    <div className='sm:mt-16 mt-8 flex flex-col'>
      <section className='flex flex-col'>
        <h1 className='font-semibold text-[32px] tracking-wide'>Beyond Software.</h1>
        <h1 className='font-medium text-[52px] tracking-wide'>Service</h1>

        <div className='w-screen sm:h-[564px]  flex sm:flex-row flex-col sm:gap-0 gap-8'>
          <div className='relative sm:w-1/2 w-full h-[364px] sm:h-auto'>
            <div className='flex flex-col absolute w-full sm:h-auto h-[364px]'>
              <div className='bg-[#A496D3] h-[44px] w-2/3 rounded-tr-lg'></div>
              <div className='bg-[#B6A8E3] w-full sm:h-[520px] h-[320px]'></div>
            </div>


            <div className='relative z-10 flex flex-col top-20 right-8 items-end'>
              <AnimatedImage src={womanOnPhone} alt='Woman on Phone' direction='left' styles='sm:w-[460px] w-[300px] rounded-lg shadow-xl' />
                <AnimatedDiv direction='bottom' styles='grid grid-cols-4 grid-flow-row bg-[#FFFCF9] rounded-xl gap-x-4 sm:gap-x-12 gap-y-4 sm:p-8 p-4 sm:mr-16 mr-8 absolute sm:top-60 top-36 sm:w-[300px]' >
                  <div className='text-2xl'><MdOutlineStars /></div>
                  <div className='text-2xl'><MdOutlineStars /></div>
                  <div className='text-2xl'><MdOutlineStars /></div>
                  <div className='text-2xl'><MdOutlineStars /></div>
                  <div><p>1</p></div>
                  <div><p>2</p></div>
                  <div><p>3</p></div>
                  <div><p>4</p></div>
                </AnimatedDiv>
            </div>

          </div>

          <div className='flex flex-col gap-6 justify-center items-center sm:ml-48 '>
            <h2 className='font-medium text-[28px] '>White Glove</h2>
            <div className='flex items-center gap-4'>
              <div className='text-2xl'><MdOutlineStars /></div>
              <p className='text-left font-medium'>
                We handle all the details: design,<br/>deployment and 
                maintenance of a<br/>custom AI chatbot for your website.
              </p>
            </div>

            <div className='flex items-center gap-4'>
              <div className='text-2xl'><MdOutlineStars /></div>
              <p className='text-left font-medium'>
                Clients receive advanced analytics<br/>reports each month plus dashboard<br/>
                access for real time tracking.
              </p>
            </div>

            <button 
              // className='bg-[#281a39] text-[#F4F4F4] hover:bg-slate-300 hover:text-content rounded-full px-5 py-2 mt-4'
              className='mt-4 bg-[#180A3F] text-white px-5 py-2 rounded-full transition duration-200 ease-in-out hover:bg-[#2A1260] hover:shadow-sm hover:-translate-y-1 active:translate-y-0'
              >
                <Link to='/contact'>Book a Demo</Link>
              </button>
          </div>
        </div>
      </section>


      <section className=''>
        <div className='w-screen flex flex-col'>
          <div className='flex gap-4 w-full justify-end'>
            {/* <div className='bg-[#BDEECE] h-[36px] w-2/3'></div> */}
            <div className='bg-[#BDEECE] h-[36px] w-1/3 rounded-tl-xl '></div>
          </div>

          <div className='flex sm:flex-row flex-col-reverse sm:h-[460px] h-[720px]'>
            <div className='flex justify-center h-full sm:justify-end sm:w-1/2 w-full'>
              <div className='flex flex-col h-full gap-5 items-center justify-center sm:mr-48'>
                <h2 className='text-[28px] font-medium'>Innovation & Community</h2>
                <div className='flex flex-col gap-6'>
                  <div className='flex items-center gap-6'>
                    <div className='text-2xl'><MdOutlineStars /></div>
                    <p className='text-left font-medium'>
                      Committed to continuously innovating<br/>and launching new updated features and<br/>
                      new product offerings.
                    </p>
                  </div>

                  <div className='flex items-center gap-6'>
                    <div className='text-2xl'><MdOutlineStars /></div>
                    <p className='text-left font-medium'>
                      Rooted in education and building<br/>community, we offer private webinars<br/>
                      with timely topics for you to learn,<br/>grow and get answers
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div className='relative sm:w-1/2 w-full h-auto'>
              <div className='w-full absolute sm:h-full h-[400px] bg-[#D9F6E4]'></div>
              <div className='relative z-10 w-full sm:h-full h-[380px]'>
                <div className='relative h-full flex sm:ml-12 ml-6 sm:mt-10 pt-6'>
                  <AnimatedImage 
                    src={speedGauge} 
                    alt='Speed Gauge'
                    direction='top'
                    styles='w-[240px] sm:w-[320px] z-10 absolute left-20 rounded-xl shadow-lg'
                  />
                  <AnimatedImage 
                    src={manWorking}
                    alt='Man Working'
                    direction='left'
                    styles='sm:w-[200px] w-[200px] z-20 absolute sm:bottom-16 bottom-2 rounded-xl shadow-xl'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default ProductPage