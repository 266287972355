import kwixilogo from '../assets/images/kwixi-logo-mint-green-black.png'

const KwixiLogo = () => {
  return (
    
      <img 
        src={kwixilogo} 
        alt='Kwixi Logo'
        className='kwixi-logo'
      />
    
  )
}

export default KwixiLogo