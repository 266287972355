import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [invalidCredential, setInvalidCredential] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoginError(null)

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/auth/signin`, {
        email, 
        password
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })

      console.log('Sign-in successful:', response.data)
      const { token } = response.data
      // setToken(token)

      localStorage.setItem('sessionToken', token)
      // window.location.href = 'http://localhost:3002/dashboard/'
      window.location.href = `${import.meta.env.VITE_DASHBOARD_URL}/?token=${token}`
      // window.location.href = `http://10.0.0.82:3003/dashboard/?token=${token}`
      // window.location.href = `https://kwixi.ai/dashboard/?token=${token}`

    } catch (error) {
      console.error('Login failed:', error)
      if (error.response && error.response.data.error) {
        const { error: errorType, message } = error.response.data
        if (errorType === 'passwordError') {
          setErrorMessage("The password you've entered is incorrect.")
          setInvalidCredential('password')
          setLoginError(true)
        } else if (errorType === 'emailError') {
          setErrorMessage("The email you've entered isn't connected to an account.")
          setInvalidCredential('email')
          setLoginError(true)
        }
      }
      return null
    }
  }

  return (
    <div className='h-full w-screen flex flex-grow justify-center items-center py-8 bg-kwixi-gradient'>
      <div className='shadow-3xl bg-base sm:w-[450px] h-fit flex flex-col sm:gap-4 gap-2 sm:p-12 p-6 rounded-lg'>
        <h2 className='sm:text-4xl text-2xl font-medium'>Sign In</h2>
        <form className='sm:p-8 p-4 flex flex-col items-center' onSubmit={handleSubmit}>
          <label className='flex flex-col sm:mb-10 mb-4 w-full'>
            <div className='label text-left'>
              <span>Email</span>
            </div>
            <input 
              type='email'
              className='border border-content border-opacity-20 rounded-md'
              id='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>

          <label className='flex flex-col mb-2 w-full'>
            <div className='text-left'>
              <span>Password</span>
            </div>
            <input 
              type='password'
              className='border border-content border-opacity-20 rounded-md'
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          {loginError && (
            <div className="text-sm text-red-500 text-start flex w-2/3">
              <p>{errorMessage} <Link className='underline'>Forgot {invalidCredential}?</Link></p>
            </div>
          )}

          <button type='submit' className='rounded-2xl shadow-md hover:bg-opacity-20  bg-kwixi-green w-fit px-12 py-1 mt-8'>Sign In</button>  
        </form>
      </div>
    </div>
  )
}

export default SignIn