import { CheckIcon } from "../components/Icons"

const AdPage = () => {
  return (
    <div className='h-full w-screen flex flex-grow justify-center items-center bg-kwixi-gradient'>
      <AdForm />
    </div>
  )
}

export default AdPage


const AdForm = () => {
  return (
    <div className='bg-white p-8 rounded-lg shadow-lg w-fit h-fit flex flex-col gap-4 items-center sm:mt-[-48px]'>
      <h2 className='font-bold text-3xl text-center mb-2'>Try an AI-Powered Chatbot for Your Business!</h2>

      <h3 className='font-semibold text-lg mb-2 max-w-lg'>
        See how an AI chatbot can engage customers, answer questions, and capture leads in real time.
        Enter your details below to get instant access to your demo chatbot!
      </h3>

      <div className='flex flex-col gap-2 mb-4'>
        <div className='flex items-center gap-2'>
          <CheckIcon className='size-5 stroke-kwixi-green' />
          <p><span className='font-semibold'>Instant setup</span> — See your chatbot in action right away</p>
        </div>

        <div className='flex items-center gap-2'>
          <CheckIcon className='size-5 stroke-kwixi-green' />
          <p><span className='font-semibold'>No committment</span> — Try it before you buy</p>
        </div>

        <div className='flex items-center gap-2'>
          <CheckIcon className='size-5 stroke-kwixi-green' />
          <p><span className='font-semibold'>Boost conversions</span> — Engage visitors 24/7</p>
        </div>
      </div>

      <div className='flex flex-col gap-8 w-3/4'>
        <div className='flex items-center justify-around'>
          <label className='w-32 text-right'>Full Name</label>
          <input
            type='text'
            className='border border-gray-200 rounded-md w-64 py-1 px-2'
          />
        </div>

        <div className='flex items-center justify-around'>
          <label className='w-32 text-right'>Email</label>
          <input
            type='text'
            className='border border-gray-200 rounded-md w-64 py-1 px-2'
          />
        </div>

        <div className='flex items-center justify-around'>
          <label className='w-32 text-right'>Phone Number</label>
          <input
            type='text'
            className='border border-gray-200 rounded-md w-64 py-1 px-2'
          />
        </div>

        <div className='flex items-center justify-around'>
          <label className='w-32 text-right'>Website URL</label>
          <input
            type='text'
            className='border border-gray-200 rounded-md w-64 py-1 px-2'
          />
        </div>
      </div>

      <button className='mt-6 mb-2 bg-kwixi-green shadow-md py-2 px-3 rounded-md hover:bg-kwixi-green/80 transition-all text-lg'> 
        Generate Demo Chatbot
      </button>
    </div>
  )
}